<!-- 登录页 -->
<template>
  <div class="Login">
    <!-- <img class="bg" src="@/assets/images/login_bg.png" alt="" /> -->
    <p class="text">喜欢，</p>
    <p class="text">就开回家</p>
    <van-field type="digit" v-model="phone" clearable center maxlength="11" placeholder="请输入手机号" />
    <van-field type="digit" v-model="code" center maxlength="6" placeholder="请输入验证码" @input="codeInput">
      <template #button>
        <span v-show="second === maxSecond && phone.length === 11" class="active" @click="getCode">
          获取验证码
        </span>
        <span v-show="second === maxSecond && phone.length !== 11">
          获取验证码
        </span>
        <span v-show="second < maxSecond">{{ second }}s后重新获取</span>
      </template>
    </van-field>
    <!-- 登录按钮 -->
    <div class="loginBtn" @click="login">
      <img class="arrow" src="@/assets/images/login_arrow.png" />
      <img class="loginOn" :style="`width:${isOk ? 1.6 : 0}rem`" src="@/assets/images/login_on.png" />
    </div>
    <!-- 底部协议 -->
    <div class="agreement">
      <img v-if="isAgreement" class="check" src="@/assets/images/checked.png" @click="isAgreement = !isAgreement" />
      <img v-else class="check" src="@/assets/images/unchecked.png" @click="isAgreement = !isAgreement" />
      我已阅读并同意
      <span class="span" @click="openPopup(1)">《服务条款》</span>与
      <span class="span" @click="openPopup(2)">《隐私政策》</span>
    </div>
    <!-- 协议弹层 -->
    <van-popup v-model="showPopup" ref="vanPopup" round position="bottom" :style="{ height: ' 100%' }">
      <div class="top">
        <p class="title">{{ agreeType === 1 ? "服务条款" : "隐私政策" }}</p>
        <p class="close" @click="closePopup">关闭</p>
      </div>
      <ServiceContract v-show="agreeType === 1" />
      <SecretPolicy v-show="agreeType === 2" />
    </van-popup>
    <PopupModal title="隐私政策" :showModal="showModal" :content="modalContent" :confirmClick="hideModal" :cancelClick="hideModal" />
  </div>
</template>

<script>
import jwtDecode from "jwt-decode";
import SecretPolicy from "./components/SecretPolicy";
import PopupModal from "@/components/PopupModal";
import ServiceContract from "./components/ServiceContract";
import { getMessageCode, userlogin } from "@/api/api_personal";
export default {
  name: "Login",
  data () {
    return {
      agreeType: 1,
      showPopup: false,
      phone: "",
      code: "",
      second: 60,
      maxSecond: 60,
      timer: null,
      isOk: false,
      isAgreement: false,
      showModal: false,
      modalContent: "请阅读并同意《服务条款》和 \n《隐私政策》后再获取验证码"
    };
  },
  computed: {},
  created () { },
  methods: {
    closePopup () {
      this.showPopup = false;
      this.$refs.vanPopup.$el.scrollTo(0, 0);
    },
    openPopup (type) {
      this.agreeType = type;
      this.showPopup = true;
    },
    codeInput (code) {
      if (code.length === 6 && this.phone.length === 11) {
        this.isOk = true;
        // this.login(); // 输入验证码自动登录
      } else {
        this.isOk = false;
      }
    },
    // 60s倒计时
    timeDown () {
      this.second--;
      this.timer = setInterval(() => {
        if (this.second === 0) {
          clearInterval(this.timer);
          this.second = this.maxSecond;
        } else {
          this.second--;
        }
      }, 1000);
    },
    // 登录
    login () {
      userlogin({
        userPhone: this.$tools.AESEncrypt(this.phone),
        verifyCode: this.code,
        appVersion: "1.0",
        deviceId: "h5"
      }).then(res => {
        if (res.code === 0) {
          const token = res.data.info;
          const info = JSON.parse(jwtDecode(token).sub); // JWT解密
          info.token = token;
          this.$store.commit("setLoginInfo", info);
          // this.$router.push({ name: "Personal" });
          const params = this.$route.params;
          console.log(params);
          // 有值
          if (params != null && Object.keys(params).length > 0) {
            if (params.name === "HOME") {
              this.$router.go(-1);
              // location.href = params.url;
            } else if (params.name === "USING_MONEY") {
              this.$router.replace({ name: "UsingMoney" });
            } else if (params.name === "BUY_ADVISORY") {
              // this.$router.go(-1);
              this.$route.params.name = "BUY_ADVISORY";
              this.$router.back();
            }
          } else {
            this.$router.go(-1);
          }
        }
      });
    },
    // 获取验证码
    getCode () {
      if (!this.isAgreement) {
        this.showModal = true;
        return;
      }
      if (!this.$tools.verifyPhone(this.phone)) {
        this.$toast("请输入正确的手机号");
        return;
      }
      this.timeDown();
      getMessageCode({
        userPhone: this.$tools.AESEncrypt(this.phone)
      }).then(res => {
        if (res.code === 0) {
          console.log(res);
        } else {
          this.second = this.maxSecond;
          clearInterval(this.timer);
        }
      });
    },
    hideModal () {
      this.showModal = false;
    }
  },
  beforeDestroy () {
    this.timer && clearTimeout(this.timer);
  },
  components: { ServiceContract, SecretPolicy, PopupModal }
};
</script>
<style lang="scss" scoped>
.Login {
  position: relative;
  height: 100%;
  padding: 0 0.4rem 0.65rem;
  background: #fff;
  .bg {
    position: absolute;
    right: 0;
    top: 0;
    width: 1.51rem;
    height: 2.2rem;
  }
  .text {
    font-size: 0.3rem;
    font-weight: bold;
    color: $mainBlack;
    line-height: 0.46rem;
    &:nth-child(2) {
      padding-top: 0.62rem;
    }
    &:nth-child(3) {
      margin-bottom: 0.2rem;
    }
  }
  .loginBtn {
    background: #d8d8d8;
    border-radius: 0.29rem;
    width: 1.6rem;
    height: 0.58rem;
    margin: 0.5rem auto;
    position: relative;
    .arrow {
      width: 0.27rem;
      height: 0.26rem;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .loginOn {
      transition: width 0.5s;
      height: 0.58rem;
    }
  }
  .agreement {
    font-size: 0.13rem;
    color: #8d8d91;
    line-height: 0.2rem;
    position: absolute;
    left: 0.4rem;
    right: 0.4rem;
    bottom: 0.65rem;
    display: flex;
    align-items: center;
    .check {
      width: 0.2rem;
      height: 0.2rem;
      margin-right: 0.06rem;
    }
    .span {
      color: $mainRed;
    }
  }
}
</style>
<style lang="scss">
.Login {
  .van-cell {
    height: 0.58rem;
    background: transparent;
    padding: 0;
    border-bottom: 1px solid #e9eaed;
    &::after {
      content: none;
    }
    input {
      font-size: 0.16rem;
      color: $mainBlack;
      &::placeholder {
        font-size: 0.16rem;
        color: #8d8d91;
      }
    }
    .van-field__button {
      font-size: 0.16rem;
      color: #6e727a;
      .active {
        color: $mainRed;
      }
    }
  }
  .van-popup--round {
    .top {
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.3);
      border-bottom: 1px solid #e8e8e8;
      height: 0.56rem;
      display: flex;
      align-items: center;
      background: #fff;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      .title {
        flex: 1;
        font-size: 0.17rem;
        font-weight: bold;
        color: $mainBlack;
        padding-left: 0.64rem;
        text-align: center;
      }
      .close {
        color: $mainRed;
        font-size: 0.14rem;
        padding: 0.1rem 0.18rem;
      }
    }
    .agreeContent {
      padding: 0.71rem 0.18rem 0.15rem;
      font-size: 0.15rem;
      color: $mainBlack;
      text-align: justify;
      line-height: 0.24rem;
      .indent {
        text-indent: 0.3rem;
      }
    }
  }
}
</style>
