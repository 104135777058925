/**
 * 个人中心
 */

import axios from "./axios";

// 获取验证码
export function getMessageCode(params) {
  return axios.post("/lyra-middleground/api/user/getMessageCode", params, {
    hideLoading: true
  });
}
// 登录注册接口
export function userlogin(params) {
  return axios.post("/lyra-middleground/api/user/login", params, {
    hideLoading: true
  });
}
// 退出登录
export function userLogout(params) {
  return axios.post("/lyra-middleground/api/user/logout", params);
}

// 更新用户接口
export function updateUserInfo(params, config) {
  return axios.post(
    "/lyra-middleground/api/user/updateUserInfo",
    params,
    config
  );
}

// 查询用户接口
export function getUserInfo(params, config) {
  return axios.post("/lyra-middleground/api/user/getUserInfo", params, config);
}

// 计算器计算
export function calEngine(params, config) {
  return axios.post("/lyra-middleground/api/base/calEngine", params, config);
}

// 订单列表
export function getOrderList(params, config) {
  return axios.post(
    "/lyra-middleground/api/order/getOrderList",
    params,
    config
  );
}
// 订单详情
export function getOrderDetail(params, config) {
  return axios.post(
    "/lyra-middleground/api/order/getOrderDetail",
    params,
    config
  );
}
// 删除订单
export function deleteOrder(params, config) {
  return axios.post("/lyra-middleground/api/order/deleteOrder", params, config);
}
// 注销
export function cancelUser(params, config) {
  return axios.post("/lyra-middleground/api/user/cancelUser", params, config);
}
