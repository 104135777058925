<!-- 提示弹框  卖车估值，登陆隐私-->
<template>
  <van-popup class="popupModal" v-model="show">
    <div class="code">
      <p v-show="title" class="title">{{ title }}</p>
      <p class="content">
        {{ content }}
      </p>
      <div v-if="showTwoBtn" class="bottmClass">
        <div class="line" />
        <div class="cancel" @click="cancelClick">
          取消
        </div>
        <div class="line2" />
        <div class="confirm" @click="confirmClick">
          确认
        </div>
      </div>
      <p v-else class="c_redBtn btn" @click="confirmClick">
        知道了
      </p>
    </div>
    <img v-show="!showTwoBtn" class="close" @click="cancelClick" src="@/assets/images/close.png" />
  </van-popup>
</template>

<script>
export default {
  name: "homeSwiper",
  props: {
    showModal: {
      type: Boolean
    },
    title: {
      type: String
    },
    content: {
      type: String
    },
    confirmClick: {
      type: Function
    },
    cancelClick: {
      type: Function
    },
    showTwoBtn: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      show: false
    };
  },
  watch: {
    showModal: {
      handler () {
        console.log("showModal", this.showModal);
        this.show = this.showModal;
      },
      immediate: true
    },
    show: {
      handler () {
        if (!this.show && this.showModal) {
          this.cancelClick();
        }
      }
    }
  },
  mounted () { },
  methods: {}
};
</script>
<style lang="scss">
.popupModal {
  background: transparent;
  width: 2.8rem;
  .code {
    background: #ffffff;
    border-radius: 0.12rem;
    // padding: 0.4rem 0.12rem;
    text-align: center;
    margin-bottom: 0.3rem;
    overflow: hidden;
    zoom: 100%;
    .title {
      font-size: 0.2rem;
      color: $mainBlack;
      line-height: 0.21rem;
      margin-top: 0.2rem;
    }
    .content {
      font-size: 0.15rem;
      color: $mainBlack;
      line-height: 0.21rem;
      margin: 0rem 0 0.25rem;
      padding: 0rem 0.18rem;
      padding-top: 0.2rem;
      white-space: break-spaces;
    }
    .letter {
      font-size: 0.12rem;
      color: #8d8d91;
      line-height: 0.17rem;
    }
    .btn {
      margin-left: 0.18rem;
      margin-right: 0.18rem;
      margin-bottom: 0.2rem;
    }
    .bottmClass {
      height: 0.58rem;
    }
    .line {
      width: 100%;
      height: 0.02rem;
      background: #f1f2f6;
    }
    .line2 {
      height: 100%;
      width: 0.02rem;
      display: inline-block;
      background: #f1f2f6;
    }
    .cancel {
      width: 49.2%;
      height: 100%;
      vertical-align: middle;
      display: inline-block;
    }
    .confirm {
      width: 49.2%;
      color: $mainRed;
      height: 100%;
      vertical-align: middle;
      display: inline-block;
    }
  }
  .close {
    margin: 0 auto;
    display: block;
    width: 0.36rem;
    height: 0.36rem;
  }
}
</style>
